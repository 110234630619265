<script>
import { SuawForm, SuawTextInput, SuawTextArea, SuawButton, SuawHeading, SuawFileUpload, SuawThumbnail } from "@/components";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "@/utils/api/validationErrors";
import { format } from "date-fns";

export default {
  name: "DraftChallengeForm",

  components: {
    SuawForm,
    SuawTextInput,
    SuawTextArea,
    SuawButton,
    SuawHeading,
    SuawFileUpload,
    SuawThumbnail
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    bodyJson: {
      type: Object,
      default: null
    },
    beginDate: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: true
    },
    imageUrl: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      form: {
        title: this.title,
        bodyJson: this.bodyJson,
        beginDate: this.beginDate,
        timeZoneId: this.timezoneId
      }
    };
  },

  computed: {
    todayDate() {
      return format(new Date(), "yyyy-MM-dd");
    }
  },

  watch: {
    title(newVal) {
      this.form.title = newVal;
    },
    bodyJson(newVal) {
      this.form.bodyJson = newVal;
    },
    beginDate(newVal) {
      this.form.beginDate = newVal;
    }
  },

  validations: {
    form: {
      title: { required },
      bodyJson: { required },
      beginDate: { required }
    }
  },

  methods: {
    validateErrors,
    formFieldState,
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$root.$emit("universal-error-message", "The challenge form is invalid.");
        return;
      }

      this.$emit("submit", { ...this.form });
    },
    onUpload(img) {
      this.$emit("upload", img);
    }
  }
};
</script>

<template>
  <SuawForm>
    <template #form>
      <div class="header">
        <SuawHeading level="2" content="Draft Challenge" />
        <div class="button-container">
          <SuawButton button-text="Save" type="primary" size="large" :disabled="!isDraft" @click="onSubmit" />
          <SuawButton v-if="isDraft" button-text="Publish" type="success" size="large" @click="$emit('publish')" />
          <SuawButton v-else button-text="Unpublish" type="critical-light" size="large" @click="$emit('unpublish')" />
        </div>
      </div>
      <SuawTextInput
        id="title"
        v-model="form.title"
        type="text"
        label="Title"
        is-required
        placeholder="Challenge title"
        :state="formFieldState($v, 'form', 'title')"
        :error-message="validateErrors($v.form.title, 'Title')"
        @blur="$v.form.title.$touch()"
      />
      <SuawTextArea
        id="bodyJson"
        v-model="form.bodyJson"
        use-tip-tap
        use-formatting
        label="Description"
        placeholder="Challenge description"
        :state="formFieldState($v, 'form', 'bodyJson')"
        :error-message="validateErrors($v.form.bodyJson, 'Description')"
        @blur="$v.form.bodyJson.$touch()"
      />
      <SuawTextInput
        id="beginDate"
        v-model="form.beginDate"
        type="date"
        :min="todayDate"
        label="Available Date"
        is-required
        placeholder="Enter Date"
        :state="formFieldState($v, 'form', 'beginDate')"
        :error-message="validateErrors($v.form.beginDate, 'Available date')"
        @blur="$v.form.beginDate.$touch()"
      />
      <SuawFileUpload upload-title="Upload Challenge Image" @file-selected="onUpload" />
      <SuawThumbnail size="fit" :content="imageUrl" />
    </template>
  </SuawForm>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.button-container {
  display: flex;
  gap: 8px;
}
</style>
