<script>
import DraftPromptList from "./DraftPromptList.vue";
import { GetChallengePromptsById } from "../challengeManageOperations.gql";
import * as ChallengeApi from "../challengeManageApi";

export default {
  name: "DraftPromptListPipe",

  components: {
    DraftPromptList
  },

  props: {
    challengeId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      resultChallenge: null
    };
  },

  computed: {
    isDraft() {
      return this.resultChallenge?.is_draft;
    },
    prompts() {
      if (!this.resultChallenge) {
        return [];
      }
      const result = [...this.resultChallenge.prompts];

      // Sort prompts based on prompt_sort array
      if (this.resultChallenge.prompt_sort && this.resultChallenge.prompt_sort.length > 0) {
        result.sort((a, b) => {
          const aIndex = this.resultChallenge.prompt_sort.indexOf(a.id);
          const bIndex = this.resultChallenge.prompt_sort.indexOf(b.id);
          return aIndex - bIndex;
        });
      }

      // Only add empty prompt when in draft mode
      if (this.resultChallenge.is_draft) {
        result.push({
          id: null,
          challenge_id: this.challengeId,
          title: "",
          body_json: null
        });
      }
      return result;
    }
  },

  apollo: {
    resultChallenge: {
      query: GetChallengePromptsById,
      variables() {
        return {
          id: this.challengeId
        };
      }
    }
  },

  methods: {
    async onPromptSaved(prompt) {
      this.$log.info(prompt);
      await this.$apollo.queries.resultChallenge.refetch();
    },

    async onPromptsReordered(newOrder) {
      const result = await ChallengeApi.reorderPrompts(this.challengeId, newOrder);

      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }

      await this.$apollo.queries.resultChallenge.refetch();
    }
  }
};
</script>

<template>
  <DraftPromptList
    v-if="!$apollo.loading"
    :challenge-id="challengeId"
    :prompts="prompts"
    :is-draft="isDraft"
    @prompt-saved="onPromptSaved"
    @prompts-reordered="onPromptsReordered"
  />
</template>
