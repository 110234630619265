import { DraftChallenge, DraftPrompt, ReorderPrompts, PublishChallenge, UnpublishChallenge, UploadChallengeImage } from "./challengeManageOperations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
import { call } from "@/utils/api/base.js";

export async function draftChallenge(title, bodyJson, availableAt, timeZoneId, challengeId = null) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Challenge does not exist."],
    [BE.NOT_DRAFT, "Challenge is not in draft state."],
    [BE.ALREADY_EXISTS, "A challenge with this title already exists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Failed to create challenge. Please try again."],
    [BE.UPDATE_FAILED, "Failed to update challenge. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DraftChallenge,
        variables: {
          challengeId,
          title,
          bodyJson,
          availableAt,
          timeZoneId,
          promptSpacingMinutes: 1440 // 24 hours
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function draftPrompt(challengeId, title, bodyJson, promptId = null) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Challenge or prompt does not exist."],
    [BE.UNLOCKED, "Prompt is not in draft state."],
    [BE.ALREADY_EXISTS, "A prompt with this title already exists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Failed to create prompt. Please try again."],
    [BE.UPDATE_FAILED, "Failed to update prompt. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DraftPrompt,
        variables: {
          challengeId,
          promptId,
          title,
          bodyJson
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function publishChallenge(challengeId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Challenge ID is required."],
    [BE.NOT_EXIST, "Challenge does not exist."],
    [BE.TOO_EARLY, "Available date must be set before publishing."],
    [BE.INVALID_LENGTH, "Challenge must have at least one prompt."],
    [BE.NUMBER_TOO_SMALL, "Prompt spacing must be at least 24 hours."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Failed to publish challenge. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: PublishChallenge,
        variables: {
          challengeId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unpublishChallenge(challengeId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Challenge ID is required."],
    [BE.NOT_EXIST, "Challenge does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Failed to unpublish challenge. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnpublishChallenge,
        variables: {
          challengeId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function reorderPrompts(challengeId, promptSort) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Challenge or prompt does not exist."],
    [BE.UNLOCKED, "Prompt is not in draft state."],
    [BE.INVALID_LENGTH, "Prompt count versus prompts reordered not match."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Failed to reorder prompts. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ReorderPrompts,
        variables: {
          challengeId,
          promptSort
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function uploadChallengeImage(challengeId, photoBase64Image) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Please choose a file to upload."],
    [BE.INVALID_FORMAT, "The file you selected was not in a valid format."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Your image failed to upload."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UploadChallengeImage,
        variables: {
          challengeId,
          photoBase64Image
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}