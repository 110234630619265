<script>
import DraftPromptFormPipe from "./DraftPromptFormPipe.vue";

export default {
  name: "DraftPromptList",

  components: {
    DraftPromptFormPipe
  },

  props: {
    challengeId: {
      type: String,
      required: true
    },
    prompts: {
      type: Array,
      required: true
    },
    isDraft: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      draggedItem: null
    };
  },

  methods: {
    onPromptSaved(prompt) {
      this.$emit("prompt-saved", prompt);
    },

    canReorder(prompt) {
      // Don't allow reordering if:
      // 1. Challenge is not in draft mode
      // 2. Prompt is locked
      // 3. Prompt is empty (no id)
      return this.isDraft && prompt.is_locked && prompt.id;
    },

    onDragStart(event, index) {
      const prompt = this.prompts[index];
      if (!this.canReorder(prompt)) {
        event.preventDefault();
        return;
      }
      this.draggedItem = index;
      event.dataTransfer.effectAllowed = "move";
    },

    onDragOver(event, index) {
      event.preventDefault();
      const prompt = this.prompts[index];
      if (!this.canReorder(prompt)) return;
    },

    onDrop(event, index) {
      event.preventDefault();
      const prompt = this.prompts[index];
      if (!this.canReorder(prompt)) return;

      // Get the new order of prompt IDs
      const items = [...this.prompts];
      const item = items[this.draggedItem];
      items.splice(this.draggedItem, 1);
      items.splice(index, 0, item);

      const newOrder = items.filter(p => p.id !== null).map(p => p.id);

      this.$emit("prompts-reordered", newOrder);
      this.draggedItem = null;
    }
  }
};
</script>

<template>
  <div>
    <div
      v-for="(prompt, index) in prompts"
      :key="index"
      class="prompt-item"
      :draggable="canReorder(prompt)"
      @dragstart="onDragStart($event, index)"
      @dragover="onDragOver($event, index)"
      @drop="onDrop($event, index)"
    >
      <div v-if="prompt.id" class="prompt-header">
        <div v-if="canReorder(prompt)" class="drag-handle">⋮⋮</div>
      </div>
      <DraftPromptFormPipe :challenge-id="challengeId" :is-draft="isDraft" :prompt="prompt" @prompt-saved="onPromptSaved" />
    </div>
  </div>
</template>

<style>
.prompt-item {
  margin-bottom: 2rem;
  background: white;
  cursor: move;
}

.prompt-item[draggable="true"]:hover {
  opacity: 0.9;
}

.prompt-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.drag-handle {
  cursor: move;
  padding: 0.5rem;
  color: #666;
  font-weight: bold;
  user-select: none;
}
</style>
