<script>
import DraftPromptForm from "./DraftPromptForm.vue";
import * as ChallengeApi from "../challengeManageApi";

export default {
  name: "DraftPromptFormPipe",

  components: {
    DraftPromptForm
  },

  props: {
    challengeId: {
      type: String,
      required: true
    },
    isDraft: {
      type: Boolean,
      default: true
    },
    prompt: {
      type: Object,
      default: null
    }
  },

  methods: {
    async onSubmit({ title, bodyJson }) {
      const result = await ChallengeApi.draftPrompt(this.challengeId, title, bodyJson, this.prompt?.id);

      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }

      var emitPrompt = {
        id: this.prompt?.id ?? result.id, //result.id is only the prompt_id if it was a new prompt
        challengeId: this.challengeId,
        title: title,
        body_json: bodyJson
      };

      this.$emit("prompt-saved", emitPrompt);
    }
  }
};
</script>

<template>
  <DraftPromptForm :challenge-id="challengeId" :is-draft="isDraft" :title="prompt?.title" :body-json="prompt?.body_json" :is-locked="prompt?.is_locked" @submit="onSubmit" />
</template>
