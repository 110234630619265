<script>
import DraftChallengeForm from "./DraftChallengeForm.vue";
import * as ChallengeApi from "../challengeManageApi";
import { GetChallengeById } from "../challengeManageOperations.gql";
import { convertFileToBase64String } from "@/utils/formatting/files.js";

export default {
  name: "DraftChallengeFormPipe",

  components: {
    DraftChallengeForm
  },

  props: {
    challengeId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      resultChallenge: null,
      formData: {
        id: undefined,
        title: undefined,
        bodyJson: undefined,
        beginDate: undefined,
        imageUrl: undefined
      }
    };
  },

  computed: {
    formId() {
      return this.formData.id ?? this.challengeId;
    },
    title() {
      return this.resultChallenge?.title;
    },
    bodyJson() {
      return this.resultChallenge?.body_json;
    },
    rsvpLimit() {
      return this.resultChallenge?.rsvp_limit;
    },
    beginDate() {
      return this.resultChallenge?.available_at?.split("T")[0];
    },
    isDraft() {
      return this.resultChallenge?.is_draft;
    },
    imageUrl() {
      return this.resultChallenge?.image_url;
    }
  },

  apollo: {
    resultChallenge: {
      query: GetChallengeById,
      variables() {
        return {
          id: this.formId
        };
      },
      skip() {
        return !this.formId;
      },
      result({ data }) {
        const challenge = data.resultChallenge;
        // Extract date from available_at
        const date = challenge.available_at.split("T")[0];

        // Update form data
        this.formData.title = challenge.title;
        this.formData.bodyJson = challenge.body_json;
        this.formData.beginDate = date;
        this.formData.imageUrl = challenge.image_url;
      }
    }
  },

  methods: {
    async onPublish() {
      const result = await ChallengeApi.publishChallenge(this.challengeId);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      // Refetch challenge data to update is_draft status
      this.$apollo.queries.resultChallenge.refetch();
    },

    async onUnpublish() {
      const result = await ChallengeApi.unpublishChallenge(this.challengeId);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      // Refetch challenge data to update is_draft status
      this.$apollo.queries.resultChallenge.refetch();
    },

    async onSubmit({ title, bodyJson, beginDate }) {
      // Format datetime with midnight time
      const availableAt = `${beginDate}T00:00:00`;
      const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const result = await ChallengeApi.draftChallenge(title, bodyJson, availableAt, timeZoneId, this.challengeId);

      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }

      // Refetch challenge data to show prompts, but only for new drafts, otherwise the id is wrong
      this.$apollo.queries.resultChallenge.refetch();
      if (!this.challengeId) {
        this.$emit("challenge-saved", result.result.id);
        this.formData.id = result.result.id;
      }

      const uploadResult = await this.uploadChallengeImage();
      if (!uploadResult.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
    },

    async convertFile(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.formData.imageUrl = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },

    async uploadChallengeImage() {
      const { imageUrl } = this.formData;
      const result = await ChallengeApi.uploadChallengeImage(this.formId, imageUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    }
  }
};
</script>

<template>
  <DraftChallengeForm
    :title="title"
    :body-json="bodyJson"
    :begin-date="beginDate"
    :is-loading="$apollo.loading"
    :is-draft="isDraft"
    :image-url="imageUrl"
    @submit="onSubmit"
    @publish="onPublish"
    @unpublish="onUnpublish"
    @upload="convertFile"
  />
</template>
