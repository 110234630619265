<script>
import { SuawForm, SuawTextInput, SuawTextArea, SuawButton, SuawHeading } from "@/components";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "@/utils/api/validationErrors";

export default {
  name: "DraftPromptForm",

  components: {
    SuawForm,
    SuawTextInput,
    SuawTextArea,
    SuawButton,
    SuawHeading
  },

  props: {
    title: {
      type: String,
      default: ""
    },
    bodyJson: {
      type: Object,
      default: null
    },
    isLocked: {
      type: Boolean,
      default: true
    },
    isDraft: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      formData: {
        title: this.title,
        bodyJson: this.bodyJson
      }
    };
  },

  validations: {
    formData: {
      title: { required },
      bodyJson: { required }
    }
  },

  computed: {
    isDisabled() {
      return !this.isDraft || !this.isLocked;
    }
  },

  methods: {
    validateErrors,
    formFieldState,

    onSubmit() {
      this.$v.formData.$touch();
      if (this.$v.formData.$invalid) {
        this.$root.$emit("universal-error-message", "The prompt form is invalid.");
        return;
      }

      this.$emit("submit", { ...this.formData });
    }
  }
};
</script>

<template>
  <SuawForm>
    <template #form>
      <div class="header">
        <SuawHeading level="2" content="Draft Prompt" />
        <SuawButton button-text="Save" type="primary" size="large" :disabled="isDisabled" @click="onSubmit" />
      </div>
      <SuawTextInput
        id="title"
        v-model="formData.title"
        type="text"
        label="Title"
        is-required
        placeholder="Prompt title"
        :state="formFieldState($v, 'formData', 'title')"
        :error-message="validateErrors($v.formData.title, 'Title')"
        @blur="$v.formData.title.$touch()"
      />
      <SuawTextArea
        id="bodyJson"
        v-model="formData.bodyJson"
        use-tip-tap
        use-formatting
        label="Description"
        placeholder="Prompt description"
        :state="formFieldState($v, 'formData', 'bodyJson')"
        :error-message="validateErrors($v.formData.bodyJson, 'Description')"
        @blur="$v.formData.bodyJson.$touch()"
      />
    </template>
  </SuawForm>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
</style>
