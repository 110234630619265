<script>
import { SuawMainContent } from "@/components";
import DraftChallengeFormPipe from "./components/DraftChallengeFormPipe.vue";
import DraftPromptListPipe from "./components/DraftPromptListPipe.vue";

export default {
  name: "ChallengeManage",

  components: {
    SuawMainContent,
    DraftChallengeFormPipe,
    DraftPromptListPipe
  },

  data() {
    return {
      draftedChallengeId: null
    };
  },

  computed: {
    challengeId() {
      return this.draftedChallengeId || this.$route.query.id;
    }
  },

  methods: {
    onChallengeSaved(id) {
      this.draftedChallengeId = id;
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <DraftChallengeFormPipe :challenge-id="challengeId" @challenge-saved="onChallengeSaved" />
    <DraftPromptListPipe v-if="challengeId" :challenge-id="challengeId" />
  </SuawMainContent>
</template>
